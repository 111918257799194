import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DisabledDateFixerDirective } from './disabled-date-fixer.directive';

@NgModule({
  declarations: [DisabledDateFixerDirective],
  imports: [CommonModule],
  exports: [DisabledDateFixerDirective],
})
export class DisabledDateFixerModule {}
