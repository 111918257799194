import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  NzDatePickerComponent,
  NzRangePickerComponent,
} from 'ng-zorro-antd/date-picker';
import { Subscription } from 'rxjs';

/**
 * @author Sebastiano Maesano, Fabio Carpinato
 * @description Ant Design ha deciso di cambiare le tempistiche di chiamata delle funzione nzDisabledDate,
 * con questa direttiva viene riportato tutto a come vogliamo noi.
 */

@Directive({
  selector: '[byDisabledDateFixer]',
})
export class DisabledDateFixerDirective implements OnInit, OnDestroy {
  @Input() nzDisabledDate: (date: Date) => boolean;

  private subscription: Subscription;

  constructor(
    @Optional() private datePicker: NzDatePickerComponent,
    @Optional() private rangePicker: NzRangePickerComponent,
  ) {}

  ngOnInit() {
    if (this.datePicker) {
      this.datePicker.nzDisabledDate = null;
      this.subscription = this.datePicker.nzOnOpenChange.subscribe(
        (isOpen: boolean) => {
          if (isOpen) {
            this.datePicker.nzDisabledDate = this.nzDisabledDate;
            return;
          }

          setTimeout(() => {
            this.datePicker.nzDisabledDate = null;
          }, 500);
        },
      );
    }

    if (this.rangePicker) {
      this.rangePicker.datePicker.nzDisabledDate = null;
      this.subscription = this.rangePicker.datePicker.nzOnOpenChange.subscribe(
        (isOpen: boolean) => {
          if (isOpen) {
            this.rangePicker.datePicker.nzDisabledDate = this.nzDisabledDate;
            return;
          }
          setTimeout(() => {
            this.rangePicker.datePicker.nzDisabledDate = null;
          }, 500);
        },
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
