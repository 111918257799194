import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RangePickerDirective } from './range-picker.directive';

@NgModule({
  declarations: [RangePickerDirective],
  imports: [CommonModule],
  exports: [RangePickerDirective],
})
export class RangePickerDirectiveModule {}
