import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { Subscription } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { GRID_BREAKPOINT } from '../../../config';

@Directive({
  selector: '[byRangePicker]',
})
export class RangePickerDirective implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();

  private inputPartChangeSubs: Subscription;

  isMobile$ = this.breakpointObserver
    .observe([GRID_BREAKPOINT.beforeMedium])
    .pipe(map(({ matches }) => matches));

  isMobile = false;

  constructor(
    private rangePicker: NzDatePickerComponent,
    private overlay: Overlay,
    private breakpointObserver: BreakpointObserver,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.setOverlayPosition();

    this.subs.add(
      this.rangePicker.nzOnOpenChange.pipe(delay(50)).subscribe((isOpen) => {
        if (!isOpen) {
          this.inputPartChangeSubs?.unsubscribe();

          return;
        }

        /**
         * @description
         * When I open the popup I always move the focus to the start date.
         * This standardizes the clicks needed to select a period. The user must always make 2 clicks to select the start date and the end date.
         *
         */
        this.onFocusRangePicker();

        this.setOverlayPosition();
      }),
    );
  }

  disabledKeyboard(isMobile: boolean) {
    /**
     * @description
     * I prevent the virtual keyboard opening
     */

    if (!this.rangePicker) {
      return;
    }
    if (isMobile) {
      this.rangePicker.nzInputReadOnly = true;
    } else {
      this.rangePicker.nzInputReadOnly = false;
    }
  }

  public ngOnInit() {
    this.subs.add(
      this.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.setOverlayPosition();
        this.disabledKeyboard(isMobile);
      }),
    );
  }

  private setAntPickerContainerScrollStyle() {
    const popupElement = document.getElementsByTagName('date-range-popup')[0];

    if (!popupElement) {
      return;
    }

    /**
     * @description
     * I calculate the height to assign to ant-picker-panel-container.
     * The height will be equal to the viewport (viewPortVisibleHeight) minus the padding of the ant-picker-dropdown-range (dropdownPickerPaddings).
     */

    const viewPortVisibleHeight = window.innerHeight;

    const dropdownPickerElement = document.getElementsByClassName(
      'ant-picker-dropdown-range',
    )[0];

    const dropdownPickerElementStyle = window.getComputedStyle(
      dropdownPickerElement,
    );

    const dropdownPickerPaddings =
      +dropdownPickerElementStyle.paddingTop.replace('px', '') +
      +dropdownPickerElementStyle.paddingBottom.replace('px', '');

    const height = viewPortVisibleHeight - dropdownPickerPaddings;

    /**
     * @description
     * If isMobile = true set the max-height and overflow-y properties,
     * in order to scroll the contents of the date-range-popup if it is overflowing.
     */

    const panelContainerElement = popupElement.querySelector(
      '.ant-picker-panel-container',
    );

    panelContainerElement.setAttribute(
      'style',
      `max-height: ${this.isMobile ? height + 'px' : 'unset'}; overflow-y: ${
        this.isMobile ? 'auto' : 'unset'
      }`,
    );

    if (this.rangePicker.nzInline) {
      return;
    }

    this.createCloseIcon(panelContainerElement);
  }

  private setOverlayPosition() {
    const overlayRef = this.rangePicker?.cdkConnectedOverlay?.overlayRef;

    this.setAntPickerContainerScrollStyle();

    if (overlayRef) {
      if (this.isMobile) {
        this.setMobilePositionStrategy(overlayRef);
      } else {
        this.setDesktopPositionStrategy(overlayRef);
      }
    }
  }

  createCloseIcon(container: Element) {
    const closeIcon = document.createElement('i');
    closeIcon.classList.add(
      'far',
      'fa-times',
      'range-picker-resposnive-close-icon',
    );

    closeIcon.style.position = 'absolute';
    closeIcon.style.top = '10px';
    closeIcon.style.right = '10px';
    closeIcon.style.fontSize = '17px';
    closeIcon.style.zIndex = '1';

    closeIcon.onclick = () => {
      this.rangePicker.close();
      this.cdr.detectChanges();
    };
    container.appendChild(closeIcon);
  }

  setMobilePositionStrategy(overlayRef: OverlayRef) {
    const positionStrategy = this.overlay.position().global();

    positionStrategy.centerHorizontally();
    positionStrategy.top();

    overlayRef.updatePositionStrategy(positionStrategy);
    overlayRef.updateScrollStrategy(this.overlay.scrollStrategies.block());
  }

  setDesktopPositionStrategy(overlayRef: OverlayRef) {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef.nativeElement)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);
    overlayRef.updatePositionStrategy(positionStrategy);
    overlayRef.updateScrollStrategy(this.overlay.scrollStrategies.reposition());
  }

  onFocusRangePicker() {
    const input =
      this.rangePicker['elementRef'].nativeElement.children[0].children[0];
    input.focus();

    this.onResetEndDateOnStartDateClick();
  }

  onResetEndDateOnStartDateClick() {
    this.inputPartChangeSubs =
      this.rangePicker.datePickerService.inputPartChange$
        .pipe(take(1))
        .subscribe((_) => {
          const value = this.rangePicker.datePickerService.value;

          this.rangePicker.datePickerService.setValue([value[0], null]);
        });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();

    this.inputPartChangeSubs?.unsubscribe();
  }

  updateFormat() {
    setTimeout(() => {
      const value = this.rangePicker.datePickerService.value;
      this.rangePicker.datePickerService.setValue([value[0], value[1]]);
    }, 100);
  }
}
